import request from "@/utils/request";

//实习日志审核
export function verifypracticestudentreport(data) {
  return request({
    url: "/portal/practice-student-report/verify",
    method: "post",
    data,
  });
}

//实习计划审核/撤回
export function getcountpracticestudentreport(data) {
  return request({
    url: "/portal/practice-student-report/get-count",
    method: "post",
    data,
  });
}

//添加/编辑实习日志
export function addpracticestudentreport(data) {
  return request({
    url: "/portal/practice-student-report/add",
    method: "post",
    data,
  });
}

export function editpracticestudentreport(data) {
  return request({
    url: "/portal/practice-student-report/edit",
    method: "post",
    data,
  });
}


//获取实习日志列表
export function getlistpracticestudentreport(params) {
  return request({
    url: "/portal/practice-student-report/get-list",
    method: "get",
    params,
  });
}

//获取实习日志详情
export function getinfopracticestudentreport(params) {
  return request({
    url: "/portal/practice-student-report/get-info",
    method: "get",
    params,
  });
}
// 实习计划
export function get_report_deatil(params) {
  return request({
    url: "/portal/practice-plan-student/get-plan-student-info",
    method: "get",
    params,
  });
}
